<template>
  <div class="text-black relative">
    <ActionBar ref="expActionBar" :expDetails="expDetails" />
    <div class="bg-white shadow-md border rounded-xl mb-3 mx-10 relative">
      <div class="details flex">
        <div class="w-1/2 p-8">
          <div class="text-black">
            <h2 class="text-2xl font-semibold mb-4">
              Details
            </h2>
            <div class="iamge-upload mb-2">
              <label
                class="font-semibold mb-2 required-field"
                for="jigsawimage"
              >
                Jigsaw Image
              </label>
              <FileInput
                :acceptedFileTypes="['jpg', 'png']"
                ref="jigsawimage"
                inputId="jigsawimage"
                inputName="jigsawimage"
                @change="inputFileSelected"
              />
            </div>
            <div class="url">
              <label for="url" class="font-semibold mb-2 required-field"
                >URL</label
              >
              <input
                class="bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-full"
                type="url"
                id="url"
                name="url"
                v-model="url"
              />
            </div>
          </div>
          <div class="flex place-content-end mt-4">
            <div v-show="errorMsg" class="text-red-500 place-self-end p-3">
              <p class="text-md font-semibold">{{ errorMsg }}</p>
            </div>
            <div>
              <div>
                <ButtonInput
                  buttonText="Preview"
                  :loading="loading"
                  @click.prevent="previewJigsaw"
                  :buttonClass="
                    buttonEnabled
                      ? 'transition shadow duration-300 ease-out tracking-wider bg-nColorBlue text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
                      : 'transition shadow duration-300 ease-out tracking-wider bg-gray-400 text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none cursor-not-allowed'
                  "
                  :loadingClass="
                    'cursor-not-allowed transition shadow duration-300 ease-out tracking-wider bg-nColorBlue text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="m-8 w-1/2 px-8 pt-8 bg-nColorBlue rounded-xl relative">
          <div v-if="previewAvailable">
            <h2 class="text-white text-xl font-semibold">Preview</h2>
            <div class="jigsaw-container w-full">
              <div id="canvas-wrap">
                <canvas id="canvas"></canvas>
                <canvas class="hide" id="image"></canvas>
                <canvas class="hide" id="image-preview"></canvas>
              </div>
            </div>
          </div>
          <div v-else class="preview-placeholder">
            <div
              class="h-96 w-full rounded-lg flex justify-center items-center"
            >
              <div class="relative text-white flex flex-col">
                <svg
                  class="w-7 place-self-center"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
                <p>
                  Preview
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Send
      ref="sendComponent"
      :sendButtonEnabled="buttonEnabled"
      :errorMsg="sendErrorMsg"
      :successMsg="sendSuccessMsg"
      :loading="sendLoading"
      @sendExperience="sendExperience($event)"
      class="mx-10"
    />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import ActionBar from "@/components/Tools/Experiences/Common/ActionBar.vue";
import ButtonInput from "@/components/Elements/ButtonInput.vue";
import FileInput from "@/components/Elements/FileInput.vue";
import Send from "@/components/Tools/Experiences/Common/Send.vue";

export default {
  props: ["expDetails", "experience_id"],
  components: { ActionBar, ButtonInput, FileInput, Send },
  created() {},
  mounted() {
    this.loadJigsawLibrary().then(() => {});
  },
  data() {
    return {
      errorMsg: null,
      successMsg: null,
      loading: false,
      sendLoading: false,
      sendErrorMsg: null,
      sendSuccessMsg: null,
      buttonEnabled: false,
      previewAvailable: false,
      url: "",
      jigsawimage: null,
    };
  },
  methods: {
    previewJigsaw() {
      if (this.buttonEnabled) {
        this.loading = true;
        const formData = new FormData();
        if (typeof this.url === "string" && this.url.length != 0) {
          formData.append("link", this.url);
        }
        formData.append(
          "jigsaw_image",
          this.jigsawimage,
          this.jigsawimage.name
        );
        ApiService.post(apiResource.tools.experiences.jigsaw.preview, formData)
          .then(({ data }) => {
            this.errorMsg = null;
            this.previewAvailable = true;
            this.loading = false;
            this.$nextTick(() => {
              var jsaw = new jigsaw.Jigsaw({
                defaultImage: data.data,
                image: jigsaw.GET["image"],
                maxWidth: 800,
                spread: 0.5,
                defaultPieces: 6,
                numberOfPieces: [10, 20, 30, 40, 50, 60, 100],
                piecesNumberTmpl: "%d Pieces",
              });
              if (jigsaw.GET["image"]) {
                jsaw.set_image(jigsaw.GET["image"]);
              }
            });
          })
          .catch((error) => {
            this.loading = false;
            this.errorMsg = error.response.data.msg;
          });
      }
    },
    inputFileSelected(e) {
      this.jigsawimage = e.target.files[0];
      this.buttonEnabled = true;
      this.$refs.sendComponent.updateEnableSendButton(true);
    },
    sendExperience(details) {
      this.$refs.sendComponent.updateLoading(true);
      const formData = new FormData();
      if (typeof this.url === "string" && this.url.length != 0) {
        formData.append("link", this.url);
      }
      formData.append("jigsaw_image", this.jigsawimage, this.jigsawimage.name);
      formData.append("name", details.name);
      formData.append("email", details.email);
      formData.append("experience_id", this.experience_id);
      ApiService.post(apiResource.tools.experiences.jigsaw.send, formData)
        .then(({ data }) => {
          this.sendSuccessMsg = data.msg;
          this.$refs.sendComponent.updateLoading(false);
          this.$refs.sendComponent.updateSuccessErrorMsgs(
            this.sendSuccessMsg,
            this.sendErrorMsg
          );
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((error) => {
          this.sendErrorMsg = error.response.data.msg;
          this.$refs.sendComponent.updateLoading(false);
          this.$refs.sendComponent.updateSuccessErrorMsgs(
            this.sendSuccessMsg,
            this.sendErrorMsg
          );
        });
    },
    loadJigsawLibrary() {
      return new Promise((resolve, reject) => {
        this.$loadScript("/library/experiences/jigsaw/js/sound.js")
          .then(() => {
            return this.$loadScript(
              "/library/experiences/jigsaw/js/event-emiter.min.js"
            );
          })
          .then(() => {
            return this.$loadScript(
              "/library/experiences/jigsaw/js/canvas-event.min.js"
            );
          })
          .then(() => {
            return this.$loadScript(
              "/library/experiences/jigsaw/js/canvas-puzzle.min.js"
            );
          })
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
  computed: {},
};
</script>
<style scoped>
.jigsaw-container {
  height: 28rem;
}

.hide {
  display: none;
}

.clear {
  clear: both;
}

/*== Jigsaw puzzle ==*/
div#canvas-wrap {
  width: 100%;
  height: 100%;
  background: #3c50e0;
}

canvas#canvas,
canvas#buffer,
canvas#image-preview {
  position: absolute;
  /* top: 45px;
  left: 0; */
}

canvas#canvas {
  z-index: 100;
}

/* .loading #canvas-wrap {
  background: url(../images/loader.gif) no-repeat center;
} */
.loading canvas {
  visibility: hidden;
}

canvas#buffer {
  z-index: 70;
}

canvas#image-preview.show {
  left: 50%;
  opacity: 0.5;
  z-index: 1000;
  pointer-events: none;
}

/*== Modal window ==*/

div#congrat {
  text-align: center;
}

div#help {
  font-size: 15px;
}

div#help ul {
  margin-left: 10px;
}

/*== mobile ==*/
@media screen and (max-width: 960px) {
  div#game-options {
    left: -30px;
  }
  #clock {
    width: 50px;
  }
  #SHOW_EDGE,
  #SHOW_MIDDLE,
  #SHOW_ALL,
  #SHOW_FILEPICKER {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  #SHOW_HELP,
  #clock {
    display: none;
  }
  #JIGSAW_SHUFFLE {
    margin-left: 15px;
  }
  #SHOW_PREVIEW {
    margin-right: 10px;
  }
}
input::file-selector-button {
  font-family: "Rajdhani";
  border: solid 1px #07003b73;
  padding: 0.25em 0.8em;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
  margin-right: 16px;
}
</style>
