<template>
  <div class="bg-white shadow-md border rounded-xl mb-3 p-8 text-black">
    <div class="text-black mb-4">
      <h2 class="text-2xl font-semibold">
        Send
      </h2>
      A link with this experience will be sent to the email given below.
    </div>
    <div class="flex gap-4 items-center">
      <div class="w-1/4">
        <label for="name" class="font-semibold mb-2 required-field">Name</label>
        <input
          class="bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-full"
          type="text"
          id="name"
          name="name"
          v-model="send.name"
        />
      </div>
      <div class="w-1/4">
        <label for="email" class="font-semibold mb-2 required-field"
          >Email</label
        >
        <input
          class="bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-full"
          type="email"
          id="email"
          name="email"
          v-model="send.email"
        />
      </div>
      <div class="w-1/3 mt-6 flex items-center">
        <ButtonInput
          buttonText="Send"
          :loading="sendLoading"
          @click="sendExperience"
          :buttonClass="
            sendButtonEnabled
              ? 'transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
              : 'transition shadow duration-300 ease-out tracking-wider bg-gray-400 text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none cursor-not-allowed'
          "
          :loadingClass="
            'cursor-not-allowed transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
          "
        />
        <div class="w-full pl-2">
          <div v-show="error" class="text-red-500">
            <p class="text-md font-semibold">{{ error }}</p>
          </div>
          <div v-show="success" class="success text-green-500">
            <p class="text-sm md:text-base font-semibold">
              {{ success }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonInput from "@/components/Elements/ButtonInput.vue";

export default {
  props: {
    errorMsg: { type: String },
    successMsg: { type: String },
    loading: { type: Boolean },
    sendButtonEnabled: { type: Boolean, default: () => true },
  },
  components: {
    ButtonInput,
  },
  created() {},
  mounted() {},
  data() {
    return {
      error: this.errorMsg,
      success: this.successMsg,
      sendButtonStatus: this.sendButtonEnabled,
      sendLoading: this.loading,
      send: {
        name: null,
        email: null,
      },
    };
  },
  methods: {
    updateSuccessErrorMsgs(successMsg, errorMsg) {
      this.success = successMsg;
      this.error = errorMsg;
    },
    updateEnableSendButton(status) {
      this.sendButtonStatus = status;
    },
    updateLoading(status) {
      this.sendLoading = status;
    },
    sendExperience() {
      if (this.sendButtonStatus) {
        if (!this.send.name || !this.send.email) {
          this.error = "Please specify the name and email";
        } else {
          this.error = null;
          this.$emit("sendExperience", this.send);
        }
      }
    },
  },
  computed: {},
  watch: {
    sendButtonEnabled(newsendButtonEnabled, oldsendButtonEnabled) {
      if (oldsendButtonEnabled != newsendButtonEnabled) {
        this.sendButtonStatus = this.sendButtonEnabled;
      }
    },
  },
};
</script>
<style scoped lang="postcss"></style>
