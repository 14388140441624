<template>
  <div class="text-black relative">
    <ActionBar ref="expActionBar" :expDetails="expDetails" />
    <div class="bg-white shadow-md border rounded-xl mb-3 mx-10 relative">
      <div class="details flex">
        <div class="w-1/2 p-8" v-if="options">
          <h2 class="text-2xl font-semibold mb-4">
            Details
          </h2>
          <label class="font-semibold mb-2 required-field">Cover Image</label>
          <div class="flex gap-4 mb-4">
            <div v-for="(item, index) in options" :key="item">
              <div class="selector mr-5">
                <input
                  class="w-4 h-4 absolute opacity-0"
                  type="radio"
                  name="option"
                  :id="'option_' + index"
                  :value="index"
                  required
                  v-model="coverImage"
                />
                <label
                  @click.prevent="assignCoverImage(index)"
                  class="ml-3 text-lg font-semibold cursor-pointer"
                  :for="'option_' + index"
                >
                  <div class="min-h-36 h-36 relative">
                    <div
                      v-show="coverImage === index"
                      class="absolute -top-4 -right-4 text-green-600 bg-white cursor-default rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="w-8 bi bi-x-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        />
                      </svg>
                    </div>
                    <img
                      :src="
                        require('@/assets/images/scratchcard/' + index + '.png')
                      "
                      class="h-32 rounded-xl"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="iamge-upload mb-2">
            <label class="font-semibold mb-2 required-field" for="revealimage">
              Reveal Image
            </label>
            <FileInput
              :acceptedFileTypes="['jpg', 'png']"
              ref="revealimage"
              inputId="revealimage"
              inputName="revealimage"
              @change="inputFileSelected"
            />
          </div>
          <div class="url">
            <label for="url" class="font-semibold mb-2 required-field"
              >URL</label
            >
            <input
              class="bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-full"
              type="url"
              id="url"
              name="url"
              v-model="url"
            />
          </div>
          <div class="flex place-content-end mt-4">
            <div v-show="errorMsg" class="text-red-500 place-self-end p-3">
              <p class="text-md font-semibold">{{ errorMsg }}</p>
            </div>
            <div>
              <div>
                <ButtonInput
                  buttonText="Preview"
                  :loading="loading"
                  @click.prevent="previewScratchCard"
                  :buttonClass="
                    buttonEnabled
                      ? 'transition shadow duration-300 ease-out tracking-wider bg-nColorBlue text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
                      : 'transition shadow duration-300 ease-out tracking-wider bg-gray-400 text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none cursor-not-allowed'
                  "
                  :loadingClass="
                    'cursor-not-allowed transition shadow duration-300 ease-out tracking-wider bg-nColorBlue text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="m-8 w-1/2 px-8 pt-8 bg-nColorBlue rounded-xl relative">
          <div v-if="previewAvailable">
            <h2 class="text-white text-xl font-semibold">Preview</h2>
            <div class="scratch-container">
              <ScratchCard
                ref="scratchCardExp"
                :topImage="
                  require('@/assets/images/scratchcard/' + coverImage + '.png')
                "
                :btmImage="uploadedRevealImage"
                :url="url"
                :key="componentKey"
              />
            </div>
          </div>
          <div v-else class="preview-placeholder">
            <div
              class="h-96 w-full rounded-lg flex justify-center items-center"
            >
              <div class="relative text-white flex flex-col">
                <svg
                  class="w-7 place-self-center"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
                <p>
                  Preview
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Send
      ref="sendComponent"
      :sendButtonEnabled="buttonEnabled"
      :errorMsg="sendErrorMsg"
      :successMsg="sendSuccessMsg"
      :loading="sendLoading"
      @sendExperience="sendExperience($event)"
      class="mx-10"
    />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import ActionBar from "@/components/Tools/Experiences/Common/ActionBar.vue";
import ButtonInput from "@/components/Elements/ButtonInput.vue";
import FileInput from "@/components/Elements/FileInput.vue";
import Send from "@/components/Tools/Experiences/Common/Send.vue";
import ScratchCard from "@/components/Tools/Experiences/ScratchCard/Preview.vue";

export default {
  props: ["expDetails", "experience_id"],
  components: { ActionBar, Send, ButtonInput, FileInput, ScratchCard },
  created() {
    ApiService.get(apiResource.tools.experiences.scratchCard.getOptions)
      .then(({ data }) => {
        this.options = data.data.options;
      })
      .catch(() => {});
  },
  mounted() {},
  data() {
    return {
      errorMsg: null,
      successMsg: null,
      loading: false,
      sendLoading: false,
      sendErrorMsg: null,
      sendSuccessMsg: null,
      buttonEnabled: false,
      previewAvailable: false,
      coverImage: null,
      revealImage: null,
      uploadedRevealImage: null,
      options: null,
      url: null,
      componentKey: 0,
    };
  },
  methods: {
    assignCoverImage(index) {
      this.coverImage = index;
    },
    previewScratchCard() {
      if (this.buttonEnabled) {
        this.loading = true;
        const formData = new FormData();
        if (typeof this.url === "string" && this.url.length != 0) {
          formData.append("link", this.url);
        }
        formData.append("cover_image", this.coverImage);
        formData.append(
          "reveal_image",
          this.revealImage,
          this.revealImage.name
        );
        ApiService.post(
          apiResource.tools.experiences.scratchCard.preview,
          formData
        )
          .then(({ data }) => {
            this.forceRerender();
            this.uploadedRevealImage = data.data;
            this.errorMsg = null;
            this.previewAvailable = true;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.errorMsg = error.response.data.msg;
          });
      }
    },
    inputFileSelected(e) {
      this.revealImage = e.target.files[0];
      this.buttonEnabled = true;
      this.$refs.sendComponent.updateEnableSendButton(true);
    },
    sendExperience(details) {
      this.$refs.sendComponent.updateLoading(true);
      const formData = new FormData();
      if (typeof this.url === "string" && this.url.length != 0) {
        formData.append("link", this.url);
      }
      formData.append("cover_image", this.coverImage);
      formData.append("reveal_image", this.revealImage, this.revealImage.name);
      formData.append("name", details.name);
      formData.append("email", details.email);
      formData.append("experience_id", this.experience_id);
      ApiService.post(apiResource.tools.experiences.scratchCard.send, formData)
        .then(({ data }) => {
          this.sendSuccessMsg = data.msg;
          this.$refs.sendComponent.updateLoading(false);
          this.$refs.sendComponent.updateSuccessErrorMsgs(
            this.sendSuccessMsg,
            this.sendErrorMsg
          );
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((error) => {
          this.sendErrorMsg = error.response.data.msg;
          this.$refs.sendComponent.updateLoading(false);
          this.$refs.sendComponent.updateSuccessErrorMsgs(
            this.sendSuccessMsg,
            this.sendErrorMsg
          );
        });
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
  computed: {},
};
</script>
<style scoped>
.scratch-container {
  height: 28rem;
}
</style>
