<template>
  <div class="mx-auto relative ml-2.5" v-if="experienceDetails">
    <Navbar class="px-10" />
    <div class="w-full">
      <Celebrate
        v-if="experienceDetails.slug == slugsConfig.tools.celebrate"
        :expDetails="experienceDetails"
        :experience_id="experience_id"
      />
      <Jigsaw
        v-if="experienceDetails.slug == slugsConfig.tools.jigsaw"
        :expDetails="experienceDetails"
        :experience_id="experience_id"
      />
      <ScratchCard
        v-if="experienceDetails.slug == slugsConfig.tools.scratchCard"
        :expDetails="experienceDetails"
        :experience_id="experience_id"
      />
      <SpinTheWheel
        v-if="experienceDetails.slug == slugsConfig.tools.spinTheWheel"
        :expDetails="experienceDetails"
        :experience_id="experience_id"
      />
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Navbar from "@/components/Common/Navbar/Plain.vue";
import Celebrate from "@/components/Tools/Experiences/Celebrate/Index.vue";
import Jigsaw from "@/components/Tools/Experiences/Jigsaw/Index.vue";
import ScratchCard from "@/components/Tools/Experiences/ScratchCard/Index.vue";
import SpinTheWheel from "@/components/Tools/Experiences/SpinTheWheel/Index.vue";
import slugsConfig from "@/common/slugs.config";

export default {
  name: "ExperienceForm",
  components: {
    Navbar,
    Celebrate,
    Jigsaw,
    ScratchCard,
    SpinTheWheel,
  },
  created() {
    ApiService.get(apiResource.tools.experienceDetails, {
      experience_id: this.experience_id,
    })
      .then(({ data }) => {
        this.experienceDetails = data.data;
      })
      .catch(() => {});
  },
  data() {
    return {
      experienceDetails: null,
      experience_id: this.$route.params.experience_id,
      slugsConfig,
    };
  },
  methods: {},
};
</script>

<style scoped lang="postcss"></style>
