<template>
  <div class="text-black relative">
    <ActionBar ref="expActionBar" :expDetails="expDetails" />
    <div class="bg-white shadow-md border rounded-xl mb-3 mx-10 relative">
      <div class="details flex">
        <div class="w-1/2 p-8">
          <div class="wheel-choices">
            <h6 class="text-2xl font-semibold mb-4">
              Customise your messages.
            </h6>
            <div class="slice mb-5">
              <label class="font-semibold mb-2 required-field" for="slice1">
                Slice 1
              </label>
              <input
                type="text"
                class="bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-full"
                name="slice1"
                id="slice1"
                placeholder="Enter slice text"
                v-model="slice1"
                required
                @change="hidePreview()"
              />
              <div class="text-sm text-red-500 mt-3">
                <p v-if="v$.slice1.$invalid && checkValidation">
                  {{ v$.slice1.$silentErrors[0].$message }}
                </p>
                <p v-if="errObj && errObj.slice1">
                  {{ errObj.slice1 }}
                </p>
              </div>
            </div>
            <div class="slice mb-5">
              <label class="font-semibold mb-2 required-field" for="slice2">
                Slice 2
              </label>
              <input
                type="text"
                class="bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-full"
                name="slice2"
                id="slice2"
                placeholder="Enter slice text"
                v-model="slice2"
                required
                @change="hidePreview()"
              />
              <div class="text-sm text-red-500 mt-3">
                <p v-if="v$.slice2.$invalid && checkValidation">
                  {{ v$.slice2.$silentErrors[0].$message }}
                </p>
                <p v-if="errObj && errObj.slice2">
                  {{ errObj.slice2 }}
                </p>
              </div>
            </div>
            <div class="slice mb-5">
              <label class="font-semibold mb-2 required-field" for="slice3">
                Slice 3
              </label>
              <input
                type="text"
                class="bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-full"
                name="slice3"
                id="slice3"
                placeholder="Enter slice text"
                v-model="slice3"
                required
                @change="hidePreview()"
              />
              <div class="text-sm text-red-500 mt-3">
                <p v-if="v$.slice3.$invalid && checkValidation">
                  {{ v$.slice3.$silentErrors[0].$message }}
                </p>
                <p v-if="errObj && errObj.slice3">
                  {{ errObj.slice3 }}
                </p>
              </div>
            </div>
            <div class="slice mb-5">
              <label class="font-semibold mb-2 required-field" for="slice4">
                Slice 4
              </label>
              <input
                type="text"
                class="bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-full"
                name="slice4"
                id="slice4"
                v-model="slice4"
                placeholder="Enter slice text"
                required
                @change="hidePreview()"
              />
              <div class="text-sm text-red-500 mt-3">
                <p v-if="v$.slice4.$invalid && checkValidation">
                  {{ v$.slice4.$silentErrors[0].$message }}
                </p>
                <p v-if="errObj && errObj.slice4">
                  {{ errObj.slice4 }}
                </p>
              </div>
            </div>
            <div class="link mb-5">
              <label class="font-semibold mb-2 required-field">
                Call to Action link
              </label>
              <input
                type="text"
                class="bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-full"
                name="link"
                id="link"
                v-model="link"
                placeholder="https://texperia.com"
                @change="hidePreview()"
              />
              <div
                class="text-sm text-red-500 mt-3"
                v-if="v$.link.$invalid && checkValidation"
              >
                {{ v$.link.$silentErrors[0].$message }}
              </div>
            </div>
            <div class="choices mt-5">
              <div class="font-semibold mb-2 required-field">
                Stop the wheel at
              </div>
              <div
                class="choice inline-block mr-10"
                v-for="(choice, i) in wheelChoices"
                :key="choice"
              >
                <input
                  class="w-4 h-4 relative top-0.5"
                  type="radio"
                  name="wheelChoice"
                  :value="i + 1"
                  :id="'slice_choice' + i"
                  v-model="endWheel"
                />
                <label
                  class=" capitalize font-semibold ml-2"
                  :for="'slice_choice' + i"
                  >{{ choice }}</label
                >
              </div>
              <div class="text-sm text-red-500 mt-3">
                <p v-if="v$.endWheel.$invalid && checkValidation">
                  {{ v$.endWheel.$silentErrors[0].$message }}
                </p>
                <p v-if="errObj && errObj.stops_at">
                  {{ errObj.stops_at }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex place-content-end mt-4">
            <div v-show="errorMsg" class="text-red-500 place-self-end p-3">
              <p class="text-md font-semibold">{{ errorMsg }}</p>
            </div>
            <div>
              <div>
                <ButtonInput
                  buttonText="Preview"
                  :loading="loading"
                  @click.prevent="preview"
                  :buttonClass="
                    buttonEnabled
                      ? 'transition shadow duration-300 ease-out tracking-wider bg-nColorBlue text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
                      : 'transition shadow duration-300 ease-out tracking-wider bg-gray-400 text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none cursor-not-allowed'
                  "
                  :loadingClass="
                    'cursor-not-allowed transition shadow duration-300 ease-out tracking-wider bg-nColorBlue text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="m-8 w-1/2 px-8 pt-8 bg-nColorBlue rounded-xl relative">
          <div v-if="previewWheel">
            <h1 class="text-4xl mt-4 mb-12 text-center font-bold text-white">
              Spin the wheel
            </h1>
            <VueWinwheel
              :segments="options"
              :prizeNumberProp="endWheel"
              :outerRadiusProp="outerRadius"
              @showPrize="openStwModal"
              :key="componentKey"
            />
            <div
              v-if="choiceModal.status"
              class="absolute top-0 left-0 z-50 inset-0 overflow-y-none"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
              <div
                class="flex items-start justify-center px-4 pb-0 text-center sm:block sm:p-0"
              >
                <div
                  class="absolute top-0 left-0 inset-0 h-full bg-gray-500 bg-opacity-75 transition-opacity"
                  aria-hidden="true"
                ></div>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                  class="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                  >&#8203;</span
                >

                <div
                  class="absolute top-1/2 w-2/3 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block items-center rounded-lg overflow-hidden shadow-xl transform transition-all justify-center"
                >
                  <div class="bg-nColorBlue pb-4 sm:p-6 sm:pb-4">
                    <div class="">
                      <div class="mt-3 text-center">
                        <h3
                          class="text-6xl text-white font-semibold"
                          id="modal-title"
                        >
                          {{ choiceModal.text }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class=" bg-nColorBlue px-4 py-3 pb-6 text-center">
                    <button
                      @click.prevent="choiceModal.status = false"
                      type="button"
                      class="w-11/12 inline-flex justify-center rounded-md border border-transparent shadow-sm px-10 uppercase py-2 bg-nButtonGreen text-base font-medium text-white"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="preview-placeholder">
            <div
              class="h-96 w-full rounded-lg flex justify-center items-center"
            >
              <div class="relative text-white flex flex-col">
                <svg
                  class="w-7 place-self-center"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
                <p>
                  Preview
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Send
      ref="sendComponent"
      :sendButtonEnabled="buttonEnabled"
      :errorMsg="sendErrorMsg"
      :successMsg="sendSuccessMsg"
      :loading="sendLoading"
      @sendExperience="sendExperience($event)"
      class="mx-10"
    />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import ActionBar from "@/components/Tools/Experiences/Common/ActionBar.vue";
import ButtonInput from "@/components/Elements/ButtonInput.vue";
import Send from "@/components/Tools/Experiences/Common/Send.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, maxLength, url } from "@vuelidate/validators";
import sliceText from "@/common/text.multiline";
import VueWinwheel from "vue-winwheel/vue-winwheel";

export default {
  props: ["expDetails", "experience_id"],
  components: { ActionBar, Send, ButtonInput, VueWinwheel },
  setup() {
    return { v$: useVuelidate() };
  },
  created() {},
  mounted() {},
  data() {
    return {
      errorMsg: null,
      successMsg: null,
      errObj: null,
      loading: false,
      sendLoading: false,
      sendErrorMsg: null,
      sendSuccessMsg: null,
      buttonEnabled: true,
      componentKey: 0,
      endWheel: null,
      candidateEmail: null,
      slice1: "Welcome \n to the company !",
      slice2: "We're Sorry.",
      slice3: "We need to \n meet again.",
      slice4: "We will \n get back to you.",
      link: null,
      choiceModal: {
        status: false,
        text: null,
      },
      outerRadius: 153,
      wheelChoices: ["slice 1", "slice 2", "slice 3", "slice 4", "random"],
      checkValidation: null,
      previewWheel: false,
      showIndex: 1,
      prize: 2,
      created: false,
    };
  },
  methods: {
    openStwModal(prize) {
      console.log(prize);
      this.choiceModal.status = true;
      this.choiceModal.text = prize;
    },
    preview() {
      this.checkValidation = true;
      if (this.v$.$invalid) {
        this.choiceModal.status = false;
        this.previewWheel = true;
      }
    },
    hidePreview() {
      this.previewWheel = false;
    },
    enableSendButton() {
      this.buttonEnabled = true;
      this.$refs.sendComponent.updateEnableSendButton(true);
    },
    sendExperience(details) {
      this.$refs.sendComponent.updateLoading(true);
      this.checkValidation = true;
      if (this.v$.$invalid) {
        let requestObj = {
          name: details.name,
          email: details.email,
          experience_id: this.experience_id,
          slice1: this.slice1,
          slice2: this.slice2,
          slice3: this.slice3,
          slice4: this.slice4,
        };

        if (this.endWheel == 5) {
          requestObj["stops_at"] = `slice${this.getRandomInt(1, 4)}`;
        } else requestObj["stops_at"] = `slice${this.endWheel}`;

        if (this.link) requestObj["link"] = this.link;

        ApiService.post(
          apiResource.tools.experiences.spinTheWheel.send,
          requestObj
        )
          .then(({ data }) => {
            this.sendSuccessMsg = data.msg;
            this.$refs.sendComponent.updateLoading(false);
            this.$refs.sendComponent.updateSuccessErrorMsgs(
              this.sendSuccessMsg,
              this.sendErrorMsg
            );
            setTimeout(() => {
              window.location.reload();
            }, 500);
          })
          .catch((error) => {
            this.sendErrorMsg = error.response.data.msg;
            this.$refs.sendComponent.updateLoading(false);
            this.$refs.sendComponent.updateSuccessErrorMsgs(
              this.sendSuccessMsg,
              this.sendErrorMsg
            );
          });
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
  computed: {
    options() {
      return [
        {
          textFillStyle: "#fff",
          fillStyle: "#FBB200",
          text: sliceText(this.slice1),
          textFontWeight: "normal",
        },
        {
          textFillStyle: "#000",
          fillStyle: "#11BCA2",
          text: sliceText(this.slice2),
          textFontWeight: "normal",
        },
        {
          textFillStyle: "#fff",
          fillStyle: "#E74D65",
          text: sliceText(this.slice3),
          textFontWeight: "normal",
          textDirection: "reversed",
        },
        {
          textFillStyle: "#000",
          fillStyle: "#F0F0F0",
          text: sliceText(this.slice4),
          textFontWeight: "normal",
          textDirection: "reversed",
        },
      ];
    },
  },
  validations() {
    return {
      endWheel: { required },
      candidateEmail: { required, email },
      slice1: { required, maxLength: maxLength(35) },
      slice2: { required, maxLength: maxLength(35) },
      slice3: { required, maxLength: maxLength(35) },
      slice4: { required, maxLength: maxLength(35) },
      link: { url },
    };
  },
};
</script>
<style scoped>
.active-category {
  background-color: #3c50e0;
  color: #fff;
}
.preview-placeholder > div {
  height: 40rem;
}
</style>
