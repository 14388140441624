<template>
  <div class="sticky">
    <div class="text-black w-full px-10 flex justify-between items-center">
      <div v-if="expDetails" class="font-semibold text-xl relative">
        <img
          :src="require('@/assets/images/button-back.svg')"
          class="h-4 absolute -left-7 top-2 cursor-pointer"
          @click.prevent="goBack()"
        />
        <div>
          <p class="text-2xl">{{ expDetails.name }}</p>
          <p class=" font-normal text-base">{{ expDetails.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    expDetails: { type: Object },
  },
  components: {},
  created() {},
  mounted() {},
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "ToolsCatalog",
      });
    },
  },
  computed: {},
};
</script>
<style scoped lang="postcss">
div.sticky {
  position: -webkit-sticky;
  position: sticky;
  height: 80px;
  top: -16px;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
div.sticky::before,
div.sticky::after {
  content: "";
  display: block;
  height: 16px;
  /* make pseudo elements sticky as well */
  position: sticky;
  -webkit-position: sticky;
}

/* SHADOW */
div.sticky::before {
  top: 48px; /* shadow is at bottom of element, so at 48 + 16 = 64px */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

/* COVER */
div.sticky::after {
  /* linear gradient from background color to transparent acts as
     a transition effect so the shadow appears gradually */
  background: linear-gradient(
    white 10%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0.4) 70%,
    transparent
  );
  top: 0;
  /* cover should fall over shadow */
  z-index: 2;
}
div.sticky > div {
  background: white;
  height: 64px;
  position: sticky;
  -webkit-position: sticky;
  top: 0px;
  /* compensate for shadow with negative margin */
  margin-top: -8px;
  /* content should fall over shadow and cover */
  z-index: 3;
}
</style>
