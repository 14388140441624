function multiLineText(text, length) {
  const lineMaxLen = length || 12;
  const wsLookup = lineMaxLen / 2; // Look backwards n characters for a whitespace
  const regex = new RegExp(
    String.raw`\s*(?:(\S{${lineMaxLen}})|([\s\S]{${lineMaxLen -
      wsLookup},${lineMaxLen}})(?!\S))`,
    "g"
  );
  return text.replace(regex, (_, x, y) => (x ? `${x}-\n` : `${y}\n`));
}

export default multiLineText;
