<template>
  <div class="scratch-card-exp outer-container" ref="container">
    <canvas
      style="z-index: 2;"
      class="canvas responsive"
      ref="canvas"
      @mousedown="handleMouseDown"
      @mousemove.prevent="handleMouseMove"
      @mouseup="handleMouseUp"
      @touchstart="handleMouseDown"
      @touchmove.prevent="handleMouseMove"
      @touchend="handleMouseUp"
    ></canvas>
    <div v-if="url">
      <a :href="url" target="_blank">
        <img
          v-if="showBottomLayer"
          class="secondLayer responsive"
          ref="btmImg"
          :src="btmImage"
        />
      </a>
    </div>
    <div v-else>
      <img
        v-if="showBottomLayer"
        class="secondLayer responsive"
        ref="btmImg"
        :src="btmImage"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topImage: {
      required: true,
      type: String,
    },
    btmImage: {
      required: true,
      type: String,
    },
    url: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      showBottomLayer: false,
      isDrawing: false,
      lastPoint: [0, 0],
      ctx: "",
      canvas: "",
      brush: "",
      brushURL: process.env.VUE_APP_ASSET_SCRATCH_CARD_BRUSH,
      image: "",
      imageRevealed: false,
    };
  },
  methods: {
    handleMouseDown(e) {
      this.isDrawing = true;
      this.lastPoint = this.getMouse(e, this.canvas);
    },
    handleMouseMove(e) {
      if (!this.isDrawing) return;
      var currentPoint = this.getMouse(e, this.canvas),
        dist = this.distanceBetween(this.lastPoint, currentPoint),
        angle = this.angleBetween(this.lastPoint, currentPoint),
        x,
        y;

      for (var i = 0; i < dist; i++) {
        x = this.lastPoint.x + Math.sin(angle) * i - this.canvas.width / 30;
        y = this.lastPoint.y + Math.cos(angle) * i - this.canvas.height / 30;
        this.ctx.globalCompositeOperation = "destination-out";
        this.ctx.drawImage(
          this.brush,
          x,
          y,
          this.canvas.width / 15,
          this.canvas.width / 15
        );
      }
      this.lastPoint = currentPoint;
    },
    handleMouseUp() {
      this.isDrawing = false;
      this.handlePercentage(this.getFilledInPixels(32));
    },
    distanceBetween(point1, point2) {
      return Math.sqrt(
        Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
      );
    },
    angleBetween(point1, point2) {
      return Math.atan2(point2.x - point1.x, point2.y - point1.y);
    },
    getMouse(e, canvas) {
      try {
        var mx,
          my,
          offsetX = 0,
          offsetY = 0;
        offsetX = this.canvas.offsetLeft - canvas.width / 2 - window.scrollX;
        offsetY = canvas.offsetTop - canvas.height / 2 - window.scrollY;
        mx = e.offsetX || e.touches[0].clientX - offsetX;
        my = e.offsetY || e.touches[0].clientY - offsetY;
        this.dbgTouchX = e.offsetX || e.touches[0].clientX;
        this.dbgTouchY = e.offsetY || e.touches[0].clientY;
        this.dbgBcrX = offsetX;
        this.dbgBcrY = offsetY;
        this.dbgMX = mx;
        this.dbgMY = my;
        return { x: mx, y: my };
      } catch (error) {
        console.log(error);
      }
    },
    handlePercentage(filledInPixels) {
      filledInPixels = filledInPixels || 0;

      if (filledInPixels > 30) {
        this.canvas.parentNode.removeChild(this.canvas);
      }
    },
    getFilledInPixels(stride) {
      if (!stride || stride < 1) {
        stride = 1;
      }

      try {
        var pixels = this.ctx.getImageData(
            0,
            0,
            this.canvas.width,
            this.canvas.height
          ),
          pdata = pixels.data,
          l = pdata.length,
          total = l / stride,
          count = 0;
      } catch (error) {
        console.log(error);
      }

      // Iterate over all pixels
      for (var i = (count = 0); i < l; i += stride) {
        if (parseInt(pdata[i]) === 0) {
          count++;
        }
      }

      return Math.round((count / total) * 100);
    },
    reportWindowSize() {
      var vue = this;
      var tempImage = new Image();
      tempImage.src = this.canvas.toDataURL();
      tempImage.onload -
        function() {
          if (
            vue.$refs.container.clientHeight > vue.$refs.container.clientWidth
          ) {
            vue.canvas.height = vue.$refs.container.clientWidth;
            vue.canvas.width = vue.$refs.container.clientWidth;
          } else {
            vue.canvas.height = vue.$refs.container.clientHeight;
            vue.canvas.width = vue.$refs.container.clientHeight;
          }
          vue.ctx.drawImage(
            tempImage,
            0,
            0,
            vue.canvas.width,
            vue.canvas.height
          );
        };
    },
  },
  mounted: function() {
    window.addEventListener("resize", this.reportWindowSize);
    this.canvas = this.$refs.canvas;
    if (this.$refs.container.clientHeight > this.$refs.container.clientWidth) {
      this.canvas.height = this.$refs.container.clientWidth;
      this.canvas.width = this.$refs.container.clientWidth;
    } else {
      this.canvas.height = this.$refs.container.clientHeight;
      this.canvas.width = this.$refs.container.clientHeight;
    }
    this.ctx = this.canvas.getContext("2d", { willReadFrequently: true });
    this.brush = new Image();
    this.image = new Image();
    this.brush.crossOrigin = "Anonymous";
    this.image.crossOrigin = "Anonymous";
    this.brush.src = this.brushURL;
    this.image.src = this.topImage;
    var vue = this;
    this.image.onload = function() {
      vue.ctx.drawImage(vue.image, 0, 0, vue.canvas.width, vue.canvas.height);
      vue.showBottomLayer = true;
    };
  },
};
</script>

<style scoped>
.debug {
  position: absolute;
  color: tomato;
  z-index: 10;
}
.outer-container {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.canvas {
  top: 0;
  left: 0;
  object-fit: contain;
  position: absolute;
}
.secondLayer {
  top: 0;
  left: 0;
  object-fit: contain;
  position: absolute;
}
.responsive {
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
