<template>
  <div class="text-black relative">
    <ActionBar ref="expActionBar" :expDetails="expDetails" />
    <div class="mx-10 relative mt-2" v-if="expDetails.details">
      <div class="w-full flex justify-between">
        <div class="w-full">
          <button
            v-if="showIndex == 'finalize'"
            class="back-button text-lg uppercase font-semibold text-gray-400 "
            @click.prevent="nextTab(back.slug)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 inline-block mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
            {{ back.text }}
          </button>
          <div class="relative w-2/4">
            <WizardStep :steps="wizardSteps" :selected="showIndex" />
          </div>
        </div>
        <div class="w-1/2">
          <div
            class="flex justify-end items-center"
            v-if="showIndex == 'options'"
          >
            <div v-if="error" class="text-nColorRed place-self-end py-6">
              <p class="text-md font-semibold">{{ error }}</p>
            </div>
            <div>
              <button
                @click.prevent="nextTab('finalize')"
                class="transition shadow duration-300 ease-out tracking-wider bg-nColorBlue text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none"
              >
                Finalize
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showIndex == 'options'">
        <div
          class="w-full bg-white shadow-md border rounded-xl my-4 pr-4"
          v-for="category in expDetails.details"
          :key="category.slug"
        >
          <h2 class="text-lg font-semibold p-4">
            {{ category.name }}
          </h2>
          <div class="flex gap-4 pl-4 pb-4">
            <div
              v-for="option in category.options"
              :key="option.slug"
              :class="{
                'border-green-600': selectedOption.slug === option.slug,
              }"
              class="bg-white border rounded-xl justify-center items-center"
            >
              <div class="selector text-center relative">
                <input
                  class="w-4 h-4 absolute opacity-0"
                  type="radio"
                  name="option"
                  :id="'option_' + option.slug"
                  :value="option.slug"
                  required
                  v-model="selectedOption"
                />
                <label
                  @click.prevent="assignOption(option)"
                  class="cursor-pointer"
                  :for="'option_' + option.slug"
                >
                  <div class="h-full">
                    <div
                      v-show="selectedOption.slug === option.slug"
                      class="absolute -top-4 -right-4 text-green-600 bg-white cursor-default rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="w-8 bi bi-x-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        />
                      </svg>
                    </div>
                    <video
                      loop
                      @mouseenter="videoPlay($event)"
                      @mouseleave="videoPause($event)"
                      :src="option.preview"
                      muted
                      class="rounded-t-xl"
                    ></video>
                  </div>
                  <span class="inline-block my-2 font-semibold">{{
                    option.name
                  }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="bg-white shadow-md border rounded-xl mb-3 relative">
          <div class="details flex justify-center">
            <div class="w-1/2 p-8" v-if="selectedOption.can_be_customized">
              <div>
                <h6 class="text-2xl font-semibold mb-4">
                  Customise
                </h6>
                <div class="slice mb-5">
                  <label class="font-semibold mb-2 required-field" for="name">
                    Name
                  </label>
                  <input
                    type="text"
                    class="bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-full"
                    name="name"
                    id="name"
                    placeholder="Enter name"
                    v-model="assetName"
                    required
                    @change="hidePreview()"
                  />
                </div>
                <div
                  class="slice mb-5"
                  v-if="selectedOption.slug === slugsConfig.celebrate.promotion"
                >
                  <label
                    class="font-semibold mb-2 required-field"
                    for="designation"
                  >
                    Designation
                  </label>
                  <input
                    type="text"
                    class="bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-full"
                    name="designation"
                    id="designation"
                    placeholder="Enter Designation"
                    v-model="assetDesignation"
                    required
                    @change="hidePreview()"
                  />
                </div>
              </div>
              <div class="flex place-content-end mt-4">
                <div v-show="errorMsg" class="text-red-500 place-self-end p-3">
                  <p class="text-md font-semibold">{{ errorMsg }}</p>
                </div>
                <div>
                  <div>
                    <ButtonInput
                      buttonText="Preview"
                      :loading="loading"
                      @click.prevent="preview"
                      :buttonClass="
                        buttonEnabled
                          ? 'transition shadow duration-300 ease-out tracking-wider bg-nColorBlue text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
                          : 'transition shadow duration-300 ease-out tracking-wider bg-gray-400 text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none cursor-not-allowed'
                      "
                      :loadingClass="
                        'cursor-not-allowed transition shadow duration-300 ease-out tracking-wider bg-nColorBlue text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="m-8 w-1/2 px-8 bg-nColorBlue rounded-xl relative">
              <div v-if="previewAvailable">
                <model-viewer
                  ref="modelViewer"
                  class=" h-96 w-full"
                  id="modelViewer"
                  name="modelViewer"
                  :src="previewModel"
                  ios-src=""
                  shadow-intensity="1"
                  shadow-softness="0"
                  camera-controls
                  camera-orbit="70deg 65deg 64"
                  autoplay
                >
                </model-viewer>
              </div>
              <div v-else class="preview-placeholder">
                <div
                  class="h-96 w-full rounded-lg flex justify-center items-center"
                >
                  <div class="relative text-white flex flex-col">
                    <svg
                      class="w-7 place-self-center"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </svg>
                    <p>
                      Preview
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Send
          ref="sendComponent"
          :sendButtonEnabled="buttonEnabled"
          :errorMsg="sendErrorMsg"
          :successMsg="sendSuccessMsg"
          :loading="sendLoading"
          @sendExperience="sendExperience($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import ActionBar from "@/components/Tools/Experiences/Common/ActionBar.vue";
import WizardStep from "@/components/Elements/WizardStep.vue";
import ButtonInput from "@/components/Elements/ButtonInput.vue";
import Send from "@/components/Tools/Experiences/Common/Send.vue";
import slugsConfig from "@/common/slugs.config";

export default {
  props: ["expDetails", "experience_id"],
  components: { ActionBar, WizardStep, ButtonInput, Send },
  created() {},
  mounted() {},
  data() {
    return {
      slugsConfig,
      error: null,
      errorMsg: null,
      successMsg: null,
      errObj: null,
      loading: false,
      sendLoading: false,
      sendErrorMsg: null,
      sendSuccessMsg: null,
      buttonEnabled: true,
      selectedOption: {
        slug: null,
      },
      wizardSteps: ["Options", "Finalize"],
      showIndex: "options",
      previewAvailable: false,
      assetName: null,
      assetDesignation: null,
      previewModel: null,
    };
  },
  methods: {
    hidePreview() {
      this.previewAvailable = false;
      this.previewModel = null;
    },
    assignOption(option) {
      this.selectedOption = option;
    },
    videoPlay(e) {
      let video = e.currentTarget;
      video.play();
    },
    videoPause(e) {
      let video = e.currentTarget;
      video.pause();
    },
    nextTab(nextIndex) {
      if (nextIndex == "finalize" && !this.selectedOption.slug) {
        this.error = "Please select an option first";
        return;
      }
      if (nextIndex == "finalize" && !this.selectedOption.can_be_customized) {
        this.previewAvailable = true;
        this.previewModel = this.selectedOption.preview_model.gltf;
      } else if (
        nextIndex == "finalize" &&
        this.selectedOption.can_be_customized
      ) {
        this.hidePreview();
      }
      this.showIndex = nextIndex;
    },
    preview() {
      this.loading = true;
      let customize = { name: this.assetName };
      if (this.selectedOption.slug === slugsConfig.celebrate.promotion) {
        customize["designation"] = this.assetDesignation;
      }
      ApiService.post(apiResource.tools.experiences.celebration.preview, {
        option: this.selectedOption.slug,
        customize: customize,
      })
        .then(({ data }) => {
          this.loading = false;
          this.previewModel = data.data.model;
          this.errorMsg = null;
          this.previewAvailable = true;
        })
        .catch((error) => {
          this.loading = false;
          this.errorMsg = error.response.data.msg;
        });
    },
    sendExperience(details) {
      this.$refs.sendComponent.updateLoading(true);
      let customize = { name: this.assetName };
      if (this.selectedOption.slug === slugsConfig.celebrate.promotion) {
        customize["designation"] = this.assetDesignation;
      }
      ApiService.post(apiResource.tools.experiences.celebration.send, {
        name: details.name,
        email: details.email,
        experience_id: this.experience_id,
        option: this.selectedOption.slug,
        customize: customize,
      })
        .then(({ data }) => {
          this.sendSuccessMsg = data.msg;
          this.$refs.sendComponent.updateLoading(false);
          this.$refs.sendComponent.updateSuccessErrorMsgs(
            this.sendSuccessMsg,
            this.sendErrorMsg
          );
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch((error) => {
          this.sendErrorMsg = error.response.data.msg;
          this.$refs.sendComponent.updateLoading(false);
          this.$refs.sendComponent.updateSuccessErrorMsgs(
            this.sendSuccessMsg,
            this.sendErrorMsg
          );
        });
    },
  },
  computed: {
    back() {
      if (this.showIndex == "finalize")
        return { slug: "options", text: "back to options" };
      else return "";
    },
  },
};
</script>
<style scoped></style>
